import { template as template_2005830d48b84ef8bd3057d678470458 } from "@ember/template-compiler";
const FKText = template_2005830d48b84ef8bd3057d678470458(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
