import { template as template_d54e9df621a742418d61d0d3812f8c65 } from "@ember/template-compiler";
const FKLabel = template_d54e9df621a742418d61d0d3812f8c65(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
