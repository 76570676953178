import { template as template_0fe22a3a9ede466595100cc06e3b613a } from "@ember/template-compiler";
import { gt } from "truth-helpers";
import icon from "discourse/helpers/d-icon";
import number from "discourse/helpers/number";
const LikesCell = template_0fe22a3a9ede466595100cc06e3b613a(`
  <td class="num likes topic-list-data">
    {{#if (gt @topic.like_count 0)}}
      <a href={{@topic.summaryUrl}}>
        {{number @topic.like_count}}
        {{icon "heart"}}
      </a>
    {{/if}}
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
